import { For, Show } from "solid-js";
import { useStore } from "@nanostores/solid";
import { $currentCategoryItems } from "../stores/category.ts";
import { $currentLocation } from "../stores/currentLocation.ts";
import anime from "animejs";
import dayjs from "dayjs";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import utcPlugin from "dayjs/plugin/utc";
import { memoize, cloneDeep } from "lodash-es";
import CATEGORY from "../assets/js/category.ts";
import { $itemMenuItem, $itemMenuShow } from "../stores/itemMenu.ts";
import ReadOnlyStoreIcons from "./readOnlyStoreIcons.tsx";

dayjs.extend(utcPlugin);
dayjs.extend(relativeTimePlugin);

export default function TrolleyItems() {
	// global
	const trolleyItems = useStore($currentCategoryItems);
	const currentLocation = useStore($currentLocation);
	// local
	let mouseTimeoutID;

	const trolleyItemsByStore = () => {
		if (currentLocation().storeName.length) {
			return trolleyItems()?.filter((item) => {
				if (item.stores?.length === 0) {
					return true;
				} else if (currentLocation().storeName?.endsWith("+")) {
					// unknown area, show all
					return true;
				}

				return item?.stores?.includes(currentLocation().storeName);
			});
		}

		return trolleyItems();
	};

	function onPointerDownItem(event: PointerEvent) {
		const targets = event.target.closest("button");

		anime({
			targets,
			scale: 0.8,
			duration: 300,
		});

		mouseTimeoutID = setTimeout(() => {
			anime({
				targets,
				easing: "easeInExpo",
				scale: 1,
				duration: 300,
				begin: () => {
					mouseTimeoutID = 0;
				},
				// delete item
				complete: () => navigator?.vibrate([50, 0, 50]),
			});
		}, 500);
	}

	function onPointerCancel(event: PointerEvent) {
		const targets = event.target.closest("button");

		anime.remove(targets);
		anime({
			targets,
			easing: "easeInExpo",
			scale: {
				value: 1,
				duration: 100,
			},
			opacity: targets.dataset.originalOpacity,
			duration: 600,
		});
		clearTimeout(mouseTimeoutID);
	}

	function onPointerUpItem(event: PointerEvent, item: Item) {
		const targets = event.target.closest("button");
		// not a full hold, go ahead and just change the checkout mode
		if (mouseTimeoutID && !$itemMenuShow.get()) {
			navigator?.vibrate(50);

			anime({
				targets,
				scale: 1,
				opacity: targets.dataset.originalOpacity,
				duration: 300,
				begin: () => {
					sendToItemMenu(item);
				},
			});
		}

		clearTimeout(mouseTimeoutID);
	}

	return (
		<section class="section p-0 min-h-[50vh] overflow-y-auto">
			<div class="flex flex-wrap justify-around mt-4 mb-4 gap-x-0 gap-y-3">
				<For each={trolleyItemsByStore()}>
					{(item) => (
						<button
							id={item._id}
							type="button"
							classList={{
								"animate-item-appear inline-flex flex-col justify-around items-center text-center w-[100px] min-h-[100px] h-auto select-none border-[0.2rem] border-secondary shadow-[1px_2px_2px_black]":
									true,
								"bg-item-background": !item.name?.includes("!") && !item.name?.includes("?"),
								"bg-red-500 text-white":
									item.name?.includes("!"),
								"bg-amber-500 text-white":
									item.name?.includes("?"),
							}}
							onPointerDown={(event) =>
								onPointerDownItem(event, item)
							}
							onPointerCancel={(event) => onPointerCancel(event)}
							onPointerUp={(event) =>
								onPointerUpItem(event, item)
							}
							style={calculateStyles(item)}
							data-original-opacity={calculateOpacity(item)}
						>
							<figure>
								<img
									loading="lazy"
									class="bg-white w-[48px] h-[48px]"
									width="48"
									height="48"
									alt={item.name + " image"}
									src={URL.createObjectURL(
										item?._attachments?.image?.data ?? new Blob(),
									)}
									onLoad={(event) => {
										// release memory
										URL.revokeObjectURL(
											event.target.src,
										);
									}}
								/>
							</figure>

							<label class="text-sm break-words">
								{createExcerpt(item.name)}
							</label>

							<Show when={item.amount > 1}>
								<div>
									<label class="text-sm italic">
										{item.amount}
									</label>
								</div>
							</Show>

							<div>
								<label v-if="item.stores && item.stores.length">
									<ReadOnlyStoreIcons stores={item.stores} />
								</label>
							</div>
						</button>
					)}
				</For>
			</div>

			<Show when={trolleyItems().length === 0}>
				<div class="text-center p-4">
					<h4 class="is-size-4">Nothing found</h4>
					<h5 class="is-size-5">¯\_(ツ)_/¯</h5>
				</div>
			</Show>
		</section>
	);
}

/**
 * Module Functions
 */

const calculateStyles = (item: Item) => {
	return {
		opacity: calculateOpacity(item),
	};
};

const calculateOpacity = memoize(
	(item: Item) => {
		// warning or urgent
		if (item.name?.includes("?") || item.name?.includes("!")) {
			return 1;
		}

		const timeStamp =
			item.category !== CATEGORY.INVENTORY
				? item.added_on
				: item.edited_on;

		const monthsSinceAdded = dayjs().diff(
			dayjs.utc(timeStamp).local(),
			"month",
		);

		if (monthsSinceAdded > 6) {
			return 0.4; // don't completely fade it away
		}

		return 1 - (monthsSinceAdded / 10);
	},
	(item) => item._rev,
);

function sendToItemMenu(item) {
	const itemMenuOpenSoundUrl = new URL(
		"/sounds/item_menu_open.mp3",
		import.meta.url,
	);

	$itemMenuItem.set(cloneDeep(item));
	$itemMenuShow.set(true);
}

function removePunctuation(text = "") {
	return text.replace(/[!?]/gi, "");
}

function createExcerpt(text = "") {
	const wordLimit = 3;
	const words = text.split(" ");

	if (words.length > wordLimit) {
		return (
			removePunctuation(text.split(" ").splice(0, 2).join(" ")) + "..."
		);
	}

	return removePunctuation(text);
}
